import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { fetchPharmacySearch } from '../../redux/actions/telehealth';
import { savePatientPersonalInfo } from '../../redux/actions/patient';
import { PageBackground } from '../../components';
import { IconSVG } from '../icon';
import i18n from 'i18next';
import statesJSON from '../../services/states';
import Select from "react-select";

const StateSelector = ({ field, form }) => {
	const options = [
		{ value: "XX", label: "Select state" },
		...statesJSON.map(st => ({
			value: st.abbreviation,
			label: `${st.name}`
		}))
	];

	const handleChange = (selectedOption) => {
		form.setFieldValue(field.name, selectedOption.value !== "XX" ? selectedOption.value : "");
	};

	const customStyles = {
		control: (base) => ({
			...base,
			cursor: "pointer",
			backgroundColor: "white",
			border: "1px solid var(--border-line)",
			borderRadius: "8px",
			marginTop: "5px",
			height: "36px",
			minHeight: "36px",
			boxShadow: "none",
			"&:hover": {
				borderColor: "var(--input-hover-border)",
			},
		}),
		dropdownIndicator: () => ({
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			cursor: "pointer",
			">svg":{
				display: "none"
			},
			"&::after": {
				content: '""',
				display: "block",
				width: "8px",
				height: "5px",
				marginRight: "7px",
				backgroundImage: "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2210%22 height=%226%22 viewBox=%220 0 10 6%22%3E%3Cpath d=%22M0 0l5 6 5-6H0z%22 fill=%22%23000%22/%3E%3C/svg%3E')",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundSize: "contain",
			},
		}),
		indicatorSeparator: () => ({
			display: "none",
		}),
		menu: (base) => ({
			...base,
			borderRadius: "0",
			border: "1px solid black",
		}),
		option: (base, { isFocused, isSelected }) => ({
			...base,
			backgroundColor: isSelected ? "#1967d2" : isFocused ? "#1967d2" : "white",
			color: isSelected || isFocused ? "white" : "black",
			"&:hover": {
				backgroundColor: "#1967d2",
			},
		}),
	};

	return (
		<Select
			options={options}
			value={options.find(option => option.value === field.value) || null}
			onChange={handleChange}
			placeholder="Select your state"
			styles={customStyles}
			isSearchable
		/>
	);
};

const PharmacySearch = (props) => {
	const alert = useAlert();
	const [selectedPharmacy, setSelectedPharmacy] = useState({});
	const [activeMarker, setActiveMarker] = useState({});
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);
	const patient = useSelector((state) => state.patient.patient_profile);
	const pharmacies = useSelector((state) => state.telehealth.pharmacies);
	const dispatch = useDispatch();
	const { history, google } = props;

	const bounds = new google.maps.LatLngBounds();
	for (let i = 0; i < pharmacies.length; i += 1) {
		bounds.extend({
			lat: pharmacies[i].latitude,
			lng: pharmacies[i].longitude,
		});
	}

	const onMarkerClick = (place, marker) => {
		setSelectedPharmacy(place);
		setActiveMarker(marker);
		setShowingInfoWindow(true);
	};

	const onMapClicked = () => {
		if (showingInfoWindow) {
			setShowingInfoWindow(false);
			setActiveMarker(null);
		}
	};

	const navigateToNextPage = () => {
		history.push('/application/choose-provider');
	};

	return (
		<>
			<div className="app-content-wrapper">
				<PageBackground
					name="wizard-pharmacy"
					cssClass="app-content-wrapper-bg-right"
				/>
				{patient && patient.pharmacy && (
					<div className="app-content" style={{ maxWidth: 1000 }}>
						<div className="app-content-block">
							<div className="app-content-block-header">
								<h1>{ i18n.t('LABEL.components.forms.pharmacy-search.current-pharmacy', 'Current Pharmacy') }</h1>
							</div>
							<div className="app-content-block-body">
								<div className="row">
									<div className="pharmacy-icon">
										<IconSVG
											name="pharmacy"
											cssClasses="pharmacy"
										/>
									</div>
									<div
										className="col"
										style={{
											justifyContent: 'center',
											alignItems: 'flex-start',
											padding: 10,
										}}
									>
										<p className="pharmaName">{patient.pharmacy.name}</p>
										<p className="pharmaAddress">{`${patient.pharmacy.address1}, ${patient.pharmacy.city}, ${patient.pharmacy.state}`}</p>
									</div>
								</div>
							</div>
							<div
								className="app-content-block-footer"
								style={{ justifyContent: 'flex-end', alignItems: 'center' }}
							>
								<button type="button" onClick={navigateToNextPage}>
									{ i18n.t('LABEL.components.forms.pharmacy-search.use-btn', 'Use this pharmacy') }
								</button>
							</div>
						</div>
					</div>
				)}

				<div className="app-content" style={{ maxWidth: 1000 }}>
					<div className="app-content-block">
						<div className="app-content-block-header">
							<h1>{ i18n.t('LABEL.components.forms.pharmacy-search.find-pharmacy', 'Find a Pharmacy') }</h1>
						</div>
						<div className="app-content-block-body">
							<Formik
								initialValues={{
									searchZipCode: patient.zip,
									searchDistance: '10',
									searchCity: patient.city,
									searchState: patient.state.abbrev,
								}}
								validate={(values) => {
									const errors = {};
									if (!values.searchZipCode && !values.searchCity) {
										errors.searchZipCode = i18n.t('LABEL.components.forms.pharmacy-search.zipcode-error-msg', 'You must enter a zipcode or a city');
										errors.searchCity = i18n.t('LABEL.components.forms.pharmacy-search.city-error-msg', 'You must enter a city or a zipcode');
									} else if (values.searchZipCode && !values.searchDistance) {
										errors.searchDistance = i18n.t('LABEL.components.forms.pharmacy-search.distance-error-msg', 'You must select a distance');
									} else if (
										!values.searchZipCode &&
										values.searchCity &&
										!values.searchState
									) {
										errors.searchState = i18n.t('LABEL.components.forms.pharmacy-search.state-error-msg', 'You must select a state');
									}
									return errors;
								}}
								onSubmit={(values) => {
									const query = {
										page: 1,
										per_page: 10,
										twenty_four_hour_pharmacy: false,
									};

									if (values.searchZipCode && values.searchDistance) {
										query.zipcode = values.searchZipCode;
										query.radius = values.searchDistance;
									} else {
										query.city = values.searchCity;
										query.state = values.searchState;
									}
									dispatch(fetchPharmacySearch(patient.id, query));
								}}
							>
								{({ errors, isSubmitting }) => (
									<Form>
										<div
											className="row mobile-column"
											style={{ justifyContent: 'space-around', alignItems: 'center' }}
										>
											<div className="input-container form-row-item">
												{errors.searchZipCode ? (
													<label htmlFor="searchZipCode" className="error">
														{errors.searchZipCode}
													</label>
												) : (
													<label htmlFor="searchZipCode">
														{ i18n.t('LABEL.components.forms.pharmacy-search.zipcode-label', 'Zip') }
													</label>
												)}
												<Field name="searchZipCode" />
											</div>
											<div className="input-container form-row-item">
												{errors.searchDistance ? (
													<label htmlFor="searchDistance" className="error">
														{errors.searchDistance}
													</label>
												) : (
													<label htmlFor="searchDistance">
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-label', 'Search Distance') }
													</label>
												)}
												<Field as="select" name="searchDistance">
													<option value="1">
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-1', '1 Mile') }
													</option>
													<option value="10" selected>
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-10', '10 Miles') }
													</option>
													<option value="25">
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-25', '25 Miles') }
													</option>
													<option value="50">
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-50', '50 Miles') }
													</option>
													<option value="75">
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-75', '75 Miles') }
													</option>
													<option value="100">
														{ i18n.t('LABEL.components.forms.pharmacy-search.distance-100', '100 Miles') }
													</option>
												</Field>
											</div>
											<center>
												<h1 style={{ color: '#9199a8' }}>
													{ i18n.t('LABEL.components.forms.pharmacy-search.or', '-OR-') }
												</h1>
											</center>
											<div className="input-container form-row-item">
												{errors.searchCity ? (
													<label htmlFor="searchCity" className="error">
														{errors.searchCity}
													</label>
												) : (
													<label htmlFor="searchCity">
														{ i18n.t('LABEL.components.forms.pharmacy-search.city-label', 'City') }
													</label>
												)}
												<Field name="searchCity" placeholder="City" />
											</div>
											<div className="input-container form-row-item">
												{errors.searchState ? (
													<label htmlFor="searchState" className="error">
														{errors.searchState}
													</label>
												) : (
													<label htmlFor="searchState">
														{ i18n.t('LABEL.components.forms.pharmacy-search.state-label', 'State') }
													</label>
												)}
												<Field
													name="searchState"
													component={StateSelector}
													options={statesJSON}
													placeholder="State"
												/>
											</div>
											<div className="button-container form-row-item-half">
												<button type="submit">
													{ i18n.t('LABEL.components.forms.pharmacy-search.search-btn', 'Search') }
												</button>
											</div>
										</div>
									</Form>
								)}
							</Formik>
							<div
								className="row mobile-column"
								style={{ display: pharmacies.length > 0 ? 'flex' : 'none' }}
							>
								<div className="col50" style={{ maxWidth: 600, height:400, position: 'relative', overflow: 'scroll' }}>
									{pharmacies.length > 0 && (
										<div className="rows" style={{ marginTop: 20 }}>
											{pharmacies.map((pharmacy) => (
												<div className="row" key={`pharmacy_search_response_${pharmacy.id}`} style={{justifyContent: 'space-around'}}>
													<div>
														<div>
															<strong>{pharmacy.name}</strong>
														</div>
														<div>{pharmacy.address1}</div>
														<div>{`${pharmacy.city}, ${pharmacy.state}`}</div>
													</div>
													<div>
														<button
															type="button"
															onClick={(event) => {
																event.preventDefault();
																const data = {
																	pharmacy_id: pharmacy.id,
																};
																dispatch(
																	savePatientPersonalInfo(data, (success) => {
																		if (success) {
																			navigateToNextPage();
																			if (props.onSubmit) {
																				props.onSubmit(pharmacy);
																			}
																		} else {
																			alert.show(i18n.t('LABEL.components.forms.pharmacy-search.problem-with-pharmacy-selection', 'Oh no!, it looks like there was a problem saving your pharmacy selection'));
																		}
																	})
																);
															}}
														>
															{ i18n.t('LABEL.components.forms.pharmacy-search.select-btn', 'Select Pharmacy') }
														</button>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
								<div className="col50" style={{ maxWidth: 600, height:400, position: 'relative' }}>
									<Map google={google} zoom={14} bounds={bounds} onClick={onMapClicked}>
										{pharmacies.map((pharmacy) => (
											<Marker
												{...pharmacy}
												position={{
													lat: pharmacy.latitude,
													lng: pharmacy.longitude,
												}}
												onClick={onMarkerClick}
											/>
										))}
										<InfoWindow marker={activeMarker} visible={showingInfoWindow}>
											{selectedPharmacy.id && (
												<div>
													<h1>{selectedPharmacy.name}</h1>
													<div>{selectedPharmacy.address1}</div>
													<div>{`${selectedPharmacy.city}, ${selectedPharmacy.state}`}</div>
													<button
														type="button"
														onClick={(event) => {
															event.preventDefault();
															const data = {
																pharmacy_id: selectedPharmacy.id,
															};
															dispatch(
																savePatientPersonalInfo(data, (success) => {
																	if (success) {
																		history.push('/application/choose-provider');
																	} else {
																		alert.show(i18n.t('LABEL.components.forms.pharmacy-search.problem-with-pharmacy-selection', 'Oh no!, it looks like there was a problem saving your pharmacy selection'));
																	}
																})
															);
															if (props.onSubmit) {
																props.onSubmit(selectedPharmacy);
															}
														}}
													>
														{ i18n.t('LABEL.components.forms.pharmacy-search.select-btn', 'Select Pharmacy') }
													</button>
												</div>
											)}
										</InfoWindow>
									</Map>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div
				className="app-content"
				style={{ maxWidth: 600, display: pharmacies.length > 0 ? 'block' : 'none' }}
			>
				<div className="app-content-block">
					<div
						style={{
							position: 'relative',
							width: '100%',
							maxWidth: 600,
							height: 400,
							marginTop: 20,
						}}
					>
						<Map google={google} zoom={14} bounds={bounds} onClick={onMapClicked}>
							{pharmacies.map((pharmacy) => (
								<Marker
									{...pharmacy}
									position={{
										lat: pharmacy.latitude,
										lng: pharmacy.longitude,
									}}
									onClick={onMarkerClick}
								/>
							))}
							<InfoWindow marker={activeMarker} visible={showingInfoWindow}>
								{selectedPharmacy.id && (
									<div>
										<h1>{selectedPharmacy.name}</h1>
										<div>{selectedPharmacy.address1}</div>
										<div>{`${selectedPharmacy.city}, ${selectedPharmacy.state}`}</div>
										<button
											type="button"
											onClick={(event) => {
												event.preventDefault();
												const data = {
													pharmacy_id: selectedPharmacy.id,
												};
												dispatch(
													savePatientPersonalInfo(data, (success) => {
														if (success) {
															history.push('/application/choose-provider');
														} else {
															alert.show(
																'Oh no!, it looks like there was a problem saving your pharmacy selection'
															);
														}
													})
												);
												if (props.onSubmit) {
													props.onSubmit(selectedPharmacy);
												}
											}}
										>
											Select Pharmacy
										</button>
									</div>
								)}
							</InfoWindow>
						</Map>
					</div>
					{pharmacies.length > 0 && (
						<div className="rows" style={{ marginTop: 20 }}>
							{pharmacies.map((pharmacy) => (
								<div className="row" key={`pharmacy_search_response_${pharmacy.id}`}>
									<div>
										<div>
											<strong>{pharmacy.name}</strong>
										</div>
										<div>{pharmacy.address1}</div>
										<div>{`${pharmacy.city}, ${pharmacy.state}`}</div>
									</div>
									<div>
										<button
											type="button"
											onClick={(event) => {
												event.preventDefault();
												const data = {
													pharmacy_id: pharmacy.id,
												};
												dispatch(
													savePatientPersonalInfo(data, (success) => {
														if (success) {
															navigateToNextPage();
															if (props.onSubmit) {
																props.onSubmit(pharmacy);
															}
														} else {
															alert.show(
																'Oh no!, it looks like there was a problem saving your pharmacy selection'
															);
														}
													})
												);
											}}
										>
											Select Pharmacy
										</button>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div> */}
			</div>
		</>
	);
};

PharmacySearch.propTypes = {
	submitButtonText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyANXXtsfMaLFci8_sgbCIuyygRyTuSRSQI',
})(withRouter(PharmacySearch));
